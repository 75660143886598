import React, { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { MdAddCircleOutline } from 'react-icons/md'
import s from "./style.module.css"
import AddForm from '../AddForm/AddForm'
import ExcelUploader from '../ExcelUploader/ExcelUploader'
import excelrule from '../../../../assets/modele excel.png'

function AddBtn({ list, setList }) {
    const [showForm, setShowForm] = useState(false)
    const [showExcelBtn, setShowExcelBtn] = useState(false)

    function onClickHandler() {
        setShowForm(true)
        setShowExcelBtn(false) // Ensure only one form is shown at a time
    }

    function onExcelClickHandler() {
        setShowExcelBtn(true)
        setShowForm(false) // Ensure only one form is shown at a time
    }

    function onCloseHandler() {
        setShowForm(false)
        setShowExcelBtn(false) // Close both forms
    }

    return (
        <div>
            {showForm ? (
                <div className={s.formContainer}>
                    <div className='right'>
                        <button
                            type='button'
                            className='btn'
                            onClick={onCloseHandler}
                        >
                            Fermer <AiOutlineClose />
                        </button>
                    </div>
                    <AddForm 
                        list={list} 
                        setList={setList}
                        setShowForm={setShowForm}
                    />
                </div>
            ) : showExcelBtn ? (
                <div className={s.formContainer}>
                    <div className='right'>
                        <button
                            type='button'
                            className='btn'
                            onClick={onCloseHandler}
                        >
                            Fermer <AiOutlineClose />
                        </button>
                    </div>
                    <div className={s.excelContainer}>
                        <h2>Ajout d'un fichier Excel de vocabulaire</h2>
                        <ExcelUploader />
                        <div className={s.caution}>
                            <h3 className={s.cautionTitle}>ATTENTION</h3>
                            Le fichier Excel doit être de cette forme :<br/>
                            <img src={excelrule} className={s.img} alt=""  />
                            <ul>
                                <li>La première ligne du fichier Excel doit contenir EXACTEMENT les titres des colonnes suivantes : "wordEnglish", "wordFrench", "level", "sentence1", "sentence2", "sentence3".</li>
                                <li>La colonne "level" ne peut être complétée que par l'une des options suivantes : "A1", "A2", "B1", "B2", "C".</li>
                                <li style={{ fontWeight: 900 }}> ! Ne pas ajouter d'espaces ni modifier les majuscules des éléments ci-dessus !</li> 
                            </ul>
                        </div>  
                    </div>
                </div>
            ) : (
                <div className={`right`}>
                    <div className={s.btnContainer}> 
                    <button
                        type='button'
                        className='btn'
                        onClick={onClickHandler}
                    >
                        Ajouter un mot <MdAddCircleOutline />
                    </button>
                    <button
                        type='button'
                        className='btn'
                        onClick={onExcelClickHandler}
                    >
                        Ajouter Excel <MdAddCircleOutline />
                    </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AddBtn
