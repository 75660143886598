import React, { useState, useEffect } from 'react';
import s from "./style.module.css";
import { AiFillSound } from "react-icons/ai";
import icon from '../../../../assets/icon.png';
import jsPDF from 'jspdf';
import CardViewer from '../CardViewer/CardViewer';

const ReviseBox = ({ cardArray }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [revise, setRevise] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.box')) {
                setIsPlaying(false);
                window.speechSynthesis.cancel();
            }
        };

        document.body.addEventListener('click', handleClickOutside);

        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const speakWord = (word, lang) => {
        const synth = window.speechSynthesis;
        const cleanWord = word.replace("/", " "); // Remplace le "/" par un espace
        const utterance = new SpeechSynthesisUtterance(cleanWord);
        utterance.lang = lang;
        synth.speak(utterance);
    };
    
    
    

    function togglePlayback() {
        if (isPlaying) {
            console.log("if")
            setIsPlaying(false);
            window.speechSynthesis.cancel();
            window.location.reload()
        } 
        else {
            console.log("else")
            // cardArray?.forEach((card) => {speakWord(card.wordFrench, 'fr-FR'); speakWord(card.wordEnglish, 'en-GB'); speakWord(card.wordEnglish, 'en-GB');});
            let delay = 0;

        cardArray?.forEach((card, index) => {
            setTimeout(() => {
                speakWord(card.wordFrench, 'fr-FR');
            }, delay * 1000);
            setTimeout(() => {
                speakWord(card.wordEnglish, 'en-GB');
            }, (delay + 5) * 1000); // Pause de 5 secondes après chaque mot français
            setTimeout(() => {
                speakWord(card.wordEnglish, 'en-GB');
            }, (delay + 8) * 1000); // Pause de 2 secondes après le premier mot anglais
            delay += 8; // Total des délais pour un mot français et deux mots anglais
            });
        }
        setIsPlaying(true);
            
    }


    const generatePDF = () => {
        const doc = new jsPDF({
            orientation: 'portrait', // Orientation du document (portrait par défaut)
            unit: 'mm', // Unité de mesure (millimètres par défaut)
            format: 'a4' // Format de papier (A4 par défaut)
        });
    
        const margin = 20; // Marge interne personnalisée en millimètres
        const pageHeight = doc.internal.pageSize.height; // Hauteur totale de la page
        const pageWidth = doc.internal.pageSize.width;
        let y = margin; // Position de départ pour le contenu
    
        // Ajouter le titre avec la date du jour uniquement sur la première page
        const today = new Date();
        const formattedDate = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
        const title = `Léa English - Vocabulaire du ${formattedDate}`;
    
        // Centrer le titre
        const titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const centerX = (doc.internal.pageSize.width - titleWidth) / 2;
    
        // Ajouter le titre centré en haut du document sur la première page
        doc.text(title, centerX, y); 
        y += 20; // Augmenter la position verticale pour le contenu
    
        // Ajouter les mots de vocabulaire
        cardArray.forEach((card, index) => {
            if (y + 10 > pageHeight - margin) { // Si l'ajout du texte dépasse la hauteur de la page
                doc.addPage(); // Ajouter une nouvelle page
                y = margin; // Réinitialiser la position verticale
            }
    
            doc.setTextColor(card.knowledge ? 'green' : 'red'); // Définir la couleur du texte en fonction de la connaissance
            const maxWidth = pageWidth - 2 * margin; // Largeur disponible en tenant compte des marges
            const lines = doc.splitTextToSize(`${card.wordFrench} - ${card.wordEnglish}`, maxWidth);
            console.log(lines)
            // doc.text(`${card.wordFrench} - ${card.wordEnglish}`, margin, y);
            lines.forEach((line) => {
                if (y + 10 > pageHeight - margin) { // Si le texte dépasse la hauteur de la page
                    doc.addPage(); // Ajouter une nouvelle page
                    y = margin; // Réinitialiser la position verticale
                }
                doc.text(line, margin, y); // Ajouter chaque ligne de texte au PDF
                y += 6; // Augmenter la position verticale pour la prochaine ligne
            });
            y += 6;
        });
    
        doc.save(`vocabulaire_${formattedDate}.pdf`);
    };

    if(revise) {
        return(
            <CardViewer cardArray={cardArray} revise={revise} setRevise={setRevise}/>
        )
    }
    
    return (
        <div className='box'>
            <div className={s.btnContainer}>
                <button
                    className='btn'
                    onClick={() => setRevise(true)}
                >
                    Réviser les flashcards
                </button>
                <button
                    className='btn'
                    onClick={generatePDF}
                >
                    Télécharger PDF
                </button>
                <button
                    className='btn'
                    onClick={togglePlayback}
                >
                    {isPlaying ? 'Arrêter' : 'Tout écouter'}
                </button>
            </div>
            <div className={s.inner}>
                <ul>
                    {cardArray?.map((card) => (
                        <li
                            key={card._id}
                            className={card.knowledge ? s.green : s.red}
                        >
                            <div className={s.word}>{card.wordFrench} - {card.wordEnglish}</div>
                            <div className={s.soundIconContainer}>
                                <AiFillSound
                                    size={22}
                                    className={s.soundIcon}
                                    onClick={() => speakWord(card.wordEnglish, 'en-GB')}
                                />
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="right">
                <img src={icon} alt="" className='favicon' />
            </div>
        </div>
    );
};

export default ReviseBox;



// Télécharger le voc en pdf
// import React, { useState, useEffect } from 'react';
// import s from "./style.module.css";
// import { AiFillSound } from "react-icons/ai";
// import icon from '../../../../assets/icon.png';
// import jsPDF from 'jspdf';

// const ReviseBox = ({ cardArray }) => {
//     const [isPlaying, setIsPlaying] = useState(false);

//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (!event.target.closest('.box')) {
//                 setIsPlaying(false);
//                 window.speechSynthesis.cancel();
//             }
//         };

//         document.body.addEventListener('click', handleClickOutside);

//         return () => {
//             document.body.removeEventListener('click', handleClickOutside);
//         };
//     }, []);

//     const speakWord = (word, lang) => {
//         const synth = window.speechSynthesis;
//         const cleanWord = word.replace("/", " "); // Remplace le "/" par un espace
//         const utterance = new SpeechSynthesisUtterance(cleanWord);
//         utterance.lang = lang;
//         synth.speak(utterance);
//     };

//     const generatePDF = () => {
//         const doc = new jsPDF();
//         let y = 10;

//         cardArray.forEach((card, index) => {
//             doc.text(`${card.wordFrench} - ${card.wordEnglish}`, 10, y);
//             y += 10;
//         });

//         doc.save("vocabulaire.pdf");
//     };

//     function togglePlayback() {
//         if (isPlaying) {
//             setIsPlaying(false);
//             window.speechSynthesis.cancel();
//             window.location.reload();
//         } else {
//             let delay = 0;

//             cardArray?.forEach((card, index) => {
//                 setTimeout(() => {
//                     speakWord(card.wordFrench, 'fr-FR');
//                 }, delay * 1000);
//                 setTimeout(() => {
//                     speakWord(card.wordEnglish, 'en-GB');
//                 }, (delay + 5) * 1000); // Pause de 5 secondes après chaque mot français
//                 setTimeout(() => {
//                     speakWord(card.wordEnglish, 'en-GB');
//                 }, (delay + 8) * 1000); // Pause de 2 secondes après le premier mot anglais
//                 delay += 8; // Total des délais pour un mot français et deux mots anglais
//             });
//         }
//         setIsPlaying(true);
//     }

//     return (
//         <div className='box'>
//             <div className="right">
//                 <button
//                     className='btn'
//                     onClick={togglePlayback}
//                 >
//                     {isPlaying ? 'Arrêter' : 'Tout écouter'}
//                 </button>
//                 <button
//                     className='btn'
//                     onClick={generatePDF}
//                 >
//                     Télécharger PDF
//                 </button>
//             </div>
//             <div className={s.inner}>
//                 <ul>
//                     {cardArray?.map((card) => (
//                         <li
//                             key={card._id}
//                             className={card.knowledge ? s.green : s.red}
//                         >
//                             <div className={s.word}>{card.wordFrench} - {card.wordEnglish}</div>
//                             <div className={s.soundIconContainer}>
//                                 <AiFillSound
//                                     size={22}
//                                     className={s.soundIcon}
//                                     onClick={() => speakWord(card.wordEnglish, 'en-GB')}
//                                 />
//                             </div>
//                         </li>
//                     ))}
//                 </ul>
//             </div>
//             <div className="right">
//                 <img src={icon} alt="" className='favicon' />
//             </div>
//         </div>
//     );
// };

// export default ReviseBox;
