import React, { useState } from 'react';
import { UserAPI } from '../../../../api/user-api';
import { useDispatch, useSelector } from 'react-redux';
import { addLearnedCard } from '../../../../store/user-slice';
import s from "./style.module.css"

function DoYouKnow({ cardId, handleNextCard, setNbCardsNotPassed}) {
  const user_id = useSelector(store => store.USER._id);
  const token = useSelector(store => store.USER.token);
  const dispatch = useDispatch()

  const addCardBackend = async (knowledge) => {
   // Effectuer l'appel API pour mettre à jour les cartes de l'utilisateur
    try {
      await UserAPI.addCard(token, user_id, cardId, knowledge);
    } catch (error) {
      console.error('Erreur lors de la mise à jour des cartes de l\'utilisateur :', error);
    }
  };

  const onClickHandler = (value) => {
    // prevIndex => (prevIndex < cardArray.length - 1 ? prevIndex + 1 : prevIndex)
    setNbCardsNotPassed((nbCards)=>(nbCards < 5 ? nbCards + 1 : nbCards))
    addCardBackend(value);
    dispatch(addLearnedCard())
    handleNextCard();
  };

  return (
    <div className='box'>
      <p>Vous le saviez ?</p>
      <div className='options'>
        <div onClick={() => onClickHandler(false)} className='option red'>
          Non
        </div>
        <div onClick={() => onClickHandler(true)} className='option green'>
          Oui
        </div>
      </div>
      <p className={s.pass} onClick={() => {handleNextCard();}}>Ne pas apprendre</p>
    </div>
  );
}

export default DoYouKnow;
