import { useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import s from "./style.module.css";
import { BsPerson } from "react-icons/bs";
import { MdLogout } from "react-icons/md";
import { useSelector } from "react-redux";
import logo from "../../../../assets/logo.png"
import { useDispatch } from "react-redux";
import { setNotConnect } from "../../../../store/admin-slice";
const ADMIN_PATH = process.env.REACT_APP_ADMIN_PATH;

export function NavALarge() {
  const [isShown, setIsShown] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function setIsSwhownTrue(e) {
    e.stopPropagation();
    setIsShown(true);
  }

  function setIsSwhownFalse(e) {
    e.stopPropagation();
    setIsShown(false);
  }

  function logoutHandler() {
    localStorage.removeItem('admin-token');
    navigate(ADMIN_PATH);
    window.location.reload(false);
  }

  return (
    <nav className={s.nav}>
      <ul className={s.ul}>
        <div className={s.logoContainer}>
          <li className={s.li}>
            <NavLink to={ADMIN_PATH}>
              <img src={logo} alt="Léa English" className={s.logo} />
            </NavLink>
          </li>
        </div>
        <div className={s.centralItems}>
          <li className={s.li}>
            <NavLink
              to={`${ADMIN_PATH}-users`}
              className={s.link}
              isActive={() => location.pathname === `${ADMIN_PATH}-users`}
              style={{ color: location.pathname === `${ADMIN_PATH}-users` ? "#F27066" : "#28356B" }}
            >
              Utilisateurs
              <span className={s.icon}>
                <BsPerson />
              </span>
            </NavLink>
          </li>
          <li className={s.li}>
            <NavLink
              to={ADMIN_PATH}
              onClick={logoutHandler}
              className={s.link}
              isActive={() => location.pathname === ADMIN_PATH}
              style={{ color: location.pathname === ADMIN_PATH ? "#F27066" : "#9BB5D8" }}
            >
              Logout
              <span className={s.icon}>
                <MdLogout />
              </span>
            </NavLink>
          </li>
        </div>
      </ul>
    </nav>
  );
}
