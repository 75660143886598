import React, { useEffect, useState } from 'react'
import { PodcastAPI } from '../../../api/podcast-api';
import PodcastList from '../../../components/User/Podcast/PodcastList/PodcastList';
import PodcastOptions from '../../../components/User/Podcast/PodcastOptions/PodcastOptions';
import { useNavigate, useParams } from 'react-router-dom';
import Filter from '../../../components/User/Podcast/Filter/Filter';
import { useSelector } from 'react-redux';
import PodcastItem from '../../../components/User/Podcast/PodcastItem/PodcastItem';
import PodcastOpened from '../../../components/User/Podcast/PodcastOpened/PodcastOpened';

function Podcasts() {
  const podcastsLikedArray = useSelector(store => store.USER.podcastsLikedArray);
  const podcastsListenedArray = useSelector(store => store.USER.podcastsListenedArray);
  const token = useSelector((store) => store.USER.token)

  const [podcastList, setPodcastList] = useState([]);
  const [allPodcastList, setAllPodcastList] = useState([]);
  const [podcastListenedList, setPodcastListenedList] = useState([]);
  const [podcastLikedList, setPodcastLikedList] = useState([]);
  const [podcastLikedAndListenedList, setPodcastLikedAndListenedList] = useState([]);
  const navigate = useNavigate()
  const [level, setLevel] = useState()
  useEffect(() => {
    const getAllPodcasts = async () => {
      try {
        const allPodcasts = await PodcastAPI.getAll(token);
          // setPodcastList([...allPodcasts,{
          //   image: "https://res.cloudinary.com/dcvvjipld/image/upload/v1711029421/Podcast/Images/Niveau%20d%C3%A9butant/IMAGE%201%20-%20at%20the%20coffee%20shop.png",
          //   isLiked: false,
          //   isListened:false,
          //   level: 1,
          //   link:"https://res.cloudinary.com/dcvvjipld/video/upload/v1711024757/Podcast/Audios/Niveau%20d%C3%A9butant/Dialogue%201%20-%20at%20the%20coffee%20shop%20%28audio%29.mp3",
          //   title:  "Dialogue test - at the coffee shop",
          //   _id: "65fc3ee4ce4340f17d3ya757"}])
        setPodcastList(allPodcasts);
        setAllPodcastList(allPodcasts)
        const likedList = allPodcasts.filter(podcast => podcastsLikedArray.includes(podcast._id));
        setPodcastLikedList(likedList)
        const listenedList = allPodcasts.filter(podcast => podcastsListenedArray.includes(podcast._id));
        setPodcastListenedList(listenedList)
        const likedAndListenedList = listenedList.filter(podcast => podcastsLikedArray.includes(podcast._id));
        setPodcastLikedAndListenedList(likedAndListenedList)
      } catch (error) {
        console.error("Error fetching podcasts:", error);
        navigate('/login')
      }
    };

    const getPodcastsByLevel = async () => {
      try {
        const allPodcasts = await PodcastAPI.getByLevel(level, token);
        setPodcastList(allPodcasts);
        setAllPodcastList(allPodcasts)
        const likedList = allPodcasts.filter(podcast => podcastsLikedArray.includes(podcast._id));
        setPodcastLikedList(likedList)
        const listenedList = allPodcasts.filter(podcast => podcastsListenedArray.includes(podcast._id));
        setPodcastListenedList(listenedList)
        const likedAndListenedList = listenedList.filter(podcast => podcastsLikedArray.includes(podcast._id));
        setPodcastLikedAndListenedList(likedAndListenedList)
      } catch (error) {
        console.error("Error fetching podcasts:", error);
        navigate('/login')
      }
    };
    level ? getPodcastsByLevel() : getAllPodcasts()
  }, [podcastsLikedArray, podcastsListenedArray, level]);
  return (
    <div>
      <h1>
        Podcast
      </h1>
      <div className='right'>
          <Filter podcastList={podcastList} setPodcastList={setPodcastList} 
            podcastLikedList={podcastLikedList} 
            podcastListenedList={podcastListenedList} 
            podcastLikedAndListenedList={podcastLikedAndListenedList} 
            allPodcastList={allPodcastList} 
            setLevel={setLevel}
          />
        </div>
      <div className='container'>
        <PodcastList podcastList={podcastList} selectedLevel={level}/>
        {/* <audio controls>
          <source src="https://drive.google.com/uc?id=19kmIOWF55AWBq9v8VWvtf_PdKggKEQJc" type="audio/mp3" />
          Votre navigateur ne prend pas en charge l'élément audio.
        </audio> */}
        {/* <PodcastOpened link="https://drive.google.com/uc?id=19kmIOWF55AWBq9v8VWvtf_PdKggKEQJc"/> */}
        {/* <PodcastOpened link="https://drive.google.com/file/d/19kmIOWF55AWBq9v8VWvtf_PdKggKEQJc/view?usp=drive_link"/> */}
      </div>
    </div>
  )
}

export default Podcasts