import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { CardAPI } from '../../../../api/card-api';
import s from "./style.module.css"

const ExcelUploader = () => {
  const [file, setFile] = useState(null);
  const adminToken = useSelector(store => store.ADMIN.token)

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    console.log("o",file)
    const formData = new FormData();
    console.log("form",formData)
    formData.append('excelFile', file);
    try {
      const response = await CardAPI.uploadExcel(formData, adminToken);
      console.log('Uploaded successfully:', response);
      window.location.reload();
      // Gérer la réponse réussie, afficher un message, mettre à jour l'interface utilisateur, etc.
    } catch (error) {
      console.error('Error uploading Excel:', error);
      // Gérer l'erreur, afficher un message d'erreur, etc.
    }
  };

  return (
    <div className={s.container}>
        <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
        <button className='btn' onClick={handleUpload}>Upload Excel</button>
    </div>
  );
};

export default ExcelUploader;
