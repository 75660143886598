import React, { useState } from 'react'
import s from "./style.module.css"
import { AiFillSound } from 'react-icons/ai';
function Sentences({sentenceArray}) {
  const [actualSentence, setActualSentence] = useState()
  const speakWord = (i) => {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(sentenceArray[i].split('\n\n')[0]);

    // Spécifiez la langue (en-GB pour l'anglais britannique)
    utterance.lang = 'en-GB';
    
    synth.speak(utterance);
  };
  return (
    <div className={s.container}>
        {sentenceArray && sentenceArray[0] && 
        <p>
          {sentenceArray[0].split('\n\n').map((sentence, index) => (
            <React.Fragment key={index}>
                {index === 0 ? 
                  <>
                  <span style={{ color: 'black' }}>{sentence}</span> 
                  <span
                    onClick={() => speakWord(0)}
                    className={s.soundContainer}>
                    <AiFillSound
                      className={s.soundIcon} 
                      size={24} 
                      />
                  </span>
                  </>
                  : 
                  sentence
                }
                <br />
            </React.Fragment>
          ))}
        </p>}<br/>
        {sentenceArray && sentenceArray[1] && 
          <p>
            {sentenceArray[1].split('\n\n').map((sentence, index) => (
            <React.Fragment key={index}>
                {index === 0 ? 
                  <>
                    <span style={{ color: 'black' }}>{sentence}</span> 
                    <span
                      onClick={() => speakWord(1)}
                      className={s.soundContainer}>
                      <AiFillSound
                        className={s.soundIcon} 
                        size={24} 
                        />
                    </span>
                  </>
                  : 
                  sentence}
                <br />
            </React.Fragment>
          ))}
          </p>}
        {sentenceArray && sentenceArray[2] && 
          <p>
            {sentenceArray[2].split('\n\n').map((sentence, index) => (
            <React.Fragment key={index}>
                {index === 0 ? 
                  <>
                    <span style={{ color: 'black' }}>{sentence}</span> 
                    <span
                      onClick={() => speakWord(2)}
                      className={s.soundContainer}
                    >
                    <AiFillSound
                      className={s.soundIcon} 
                      size={24} 
                      />
                    </span>
                  </>
                    : 
                  sentence
                }
                <br />
            </React.Fragment>
          ))}
        </p>}
    </div>
  )
}

export default Sentences